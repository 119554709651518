import './App.css';
import React from "react";
import { Box, Link, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const Home = () => {
  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <img src='rose-city-light.svg' style={{ width: '80%', maxWidth: '600px' }} />
      <Typography variant='h6'> Windsor Area Photo Booth Rentals </Typography>
      <Link href='Tel: 226-344-1793' variant='h6'> 226-344-1793 </Link>
    </Box>
  )
}

const Photos = () => {
  const queryParameters = new URLSearchParams(window.location.search)
  const url1 = queryParameters.get("url1") && queryParameters.get("url1").includes('https://storage.googleapis.com/artifacts.rose-city-photo-booth.appspot.com/') ? queryParameters.get("url1") : ''
  const url2 = queryParameters.get("url2") && queryParameters.get("url2").includes('https://storage.googleapis.com/artifacts.rose-city-photo-booth.appspot.com/') ? queryParameters.get("url2") : ''
  const url3 = queryParameters.get("url3") && queryParameters.get("url3").includes('https://storage.googleapis.com/artifacts.rose-city-photo-booth.appspot.com/') ? queryParameters.get("url3") : ''
  const url4 = queryParameters.get("url4") && queryParameters.get("url4").includes('https://storage.googleapis.com/artifacts.rose-city-photo-booth.appspot.com/') ? queryParameters.get("url4") : ''
  return (
    <>
      <div>
        <img src={url1} />
      </div>
      <div>
        <img src={url2} />
      </div>
      <div>
        <img src={url3} />
      </div>
      <div>
        <img src={url4} />
      </div>
    </>

  )
}

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/photos" element={<Photos />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
